@import '../../vars.scss';

@mixin base-option(){
	@include no-select();

	margin: 0 5px 0 0;
	padding: 5px 10px;
	border-radius: 5px;
	transition:
		background-color 200ms ease-in-out,
		color 200ms ease-in-out;

	&:first-child{
		margin-left: 5px;
	}
}

.bra-switcher{
	display: flex;
	flex-direction: row;

	padding: 5px;
	border: $common-border-style;
	border-radius: 5px;

	font-size: 13px;
	line-height: 15px;
	font-weight: 400;

	&__option{
		@include base-option();
		cursor: pointer;
		background-color: transparent;
		color: $gray1;

		&--selected{
			@include base-option();
			background-color: $gray1;
			color: $white;
		}
	}
}

.bra-switcher--disabled{
	@extend .bra-switcher;
	.bra-switcher__option{
		cursor: default;
		color: $gray3;

		&--selected{
			background-color: $gray3;
		}	
	}
}