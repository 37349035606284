@import '../../vars.scss';

.two-records-plate{
	display: flex;
	flex-direction: row;
	overflow: hidden;

	&__forward-record{
		margin-right: 10px;

	}
	&__backward-record{
		color: $gray2;
	}

	&__record{
		&__text{
			&--highlighted{
				background-color: $blue50;
			}
		}
	}
}