$common-border-color: rgb(217, 217, 217);
$strong-border-color: #b9b9b9;
$common-border-style: 1px solid $common-border-color;
$common-border-radius: 2px;

$white: #FFF;
$black: #2B2B2B;

$orange10: #FDD49A;
$red: #EB5757;
$green: #22EE55;

$blue10: #468EEF;
$blue20: #2F80ED;
$blue30: #2970CF;
$blue50: rgba(70, 143, 239, 0.3);

$gray1: #333;
$gray2: #767676;
$gray3: #B6B6B6;
$gray4: #CCC;
$gray5: #E6E6E6;
$gray6: #F8F8F8;
$gray7: #FAFAFA;

$text-color-common: $gray2;
$text-color-emphasized: #222;

$tree-selecton-row-color: #E4F0FF;
$tree-selecton-cell-color: #a0b8ce;

@mixin no-select() {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Old versions of Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}

@mixin ant-table-fix(){
	//set correct height without specifying it in pixels
	*:not(.ant-table-cell){
		.ant-table-wrapper {
			height: 100%;

			.ant-spin-nested-loading{
				height: 100%;
	
				.ant-spin-container{
					height: 100%;
	
					.ant-table{
						height: 100%;
	
						.ant-table-container{
							height: 100%;
							display: flex;
							flex-direction: column;
	
	
							.header-cell-container{
								padding: 0;
							}
	
							.ant-table-header{
								flex: 0 0 auto;
							}
	
							.ant-table-body{
								flex: 1 1 auto;
								overflow: auto !important;
							}
						}
					}
				}
			}
		}
	}
}