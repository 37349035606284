@import '../../vars.scss';

@import './AttributesTable.scss';

.attributes-dialog{
	font-size: 13px;
	line-height: 15px;
	font-weight: 400;

	@mixin paddedElement {
		padding: 25px 21px 0 21px;
		&:first-child{
			padding-top: 11px;
		}
	}

	&__header{
		@include paddedElement();
		font-size: 18px;
		line-height: 20px;
		font-weight: 600;


		&__prefix{
			color: $gray3;
		}
	}

	&__switcher{
		@include paddedElement();
		display: flex;
		flex-direction: row;

		&__component{
			flex: 0 0 auto;
		}

		&__new-attr{
			flex: 0 0 auto;
			margin-left: auto;
		}
	}

	&__content{
		@include paddedElement();
		display: flex;
		min-height: 300px;

		&__table{
			flex: 1 1 auto;
		}
	}
}