.pivot-report-table{
	height: 100%;
	padding-bottom: 4px;

  .hide-scroll {
    
    &::-webkit-scrollbar{
      display: none;
      width: 0;
  }
  }

	&__table{
		.dx-pivotgrid-container {
			overflow: auto;

			.dx-area-data-cell{
				table{
					tr:hover{
						background-color: rgba(0, 0, 0, 0.2);
					}
				}
			}
		}
	}

	&__data{
		font-family: 'Lato';
	}
}