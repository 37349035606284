@import '../../vars.scss';

.pivot-report-options{
	height: 100%;
	width: 250px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: stretch;

	border-left: 1px solid $gray5;


	&__header{
		margin: 0;
		background-color: $blue10;
		color: $white;

		&__buttons{
			margin: 15px 20px;
			display: flex;

			&__collapse{
				flex: 0 0 auto;
				margin-right: auto;
				display: flex;
				align-items: center;
				font-weight: normal;
				font-size: 11px;
				line-height: 16px;

				cursor: pointer;
			}
		}

		&__text{
			margin: 20px;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
		}
	}

	&__dates{
		flex: 0 0 auto;

		&__text{
			margin: 15px 20px 10px 13px;

			font-size: 11px;
			line-height: 15px;
		}

		&__selector{
			margin: 0 20px;
      
      &__tag {
        cursor: pointer;
      }

      &__tag {
        cursor: pointer;
      }

			// Thanks to designers!
			& .ant-picker{
				& .ant-picker-input > input{
					font-size: 13px;
				}
				& .ant-picker-range-separator{
					padding: 2px;
				}
			}
		}
	}

	&__fields{
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;

		&__text{
			flex: 0 0 auto;

			display: flex;

			margin: 15px 20px 10px;

			font-weight: 500;
			font-size: 11px;
			line-height: 15px;

			&__content{
				margin-right: auto;
			}
		}

		&__templates{
			max-height: 200px;
			max-width: 200px;
			overflow: auto;

			&__template{
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 2px 5px;
				cursor: pointer;
				transition: 100ms ease-in-out background-color;

				&:hover{
					background-color: $gray5;
				}

				&__name{
					flex: 1 1 auto;
				}

				&__x{
					flex: 0 0 auto;
					height: 100%;
					display: flex;
					border-radius: 20px;
					color: $gray4;
					transition:
						100ms ease-in-out background-color,
						100ms ease-in-out color;

					.feather-ico{
						margin: 3px;
					}

					&:hover{
						color: $gray1;
						background-color: $gray3;
					}
				}
			}
		}

		&__selector{
			flex: 1 1 100px;
			overflow: auto;
			margin: 10px 20px 20px 0;
		}
	}

	&__buttons{
		display: flex;

		&__export{
			flex: 1 1 auto;
			margin: 10px 20px 20px 20px;
		}
	}

	&--collapsed{
		height: 100%;
		overflow: hidden;
		background-color: $blue30;
		color: $white;

		cursor: pointer;

		&__button{
			margin: 15px 8px;

			.feather-ico{
				margin-right: 0;
			}
		}
	}
}
