@import '../../vars.scss';

.pivot-report{
	height: 100%;

	&__table{
		flex: 1 1 auto;
		height: 100%;
		overflow: hidden;
	}

	&__properties{
		flex: 0 0 auto;
	}
}