@import '../../vars.scss';

$dialog-width: 600px;
$dialog-height: 500px;

$left-space: 55px;
$right-space: 55px;

.balance-dialog{
	border-radius: 10px;
	left: calc(50% - #{$dialog-width/2});
	top: calc(50% - #{$dialog-height/2});
	width: $dialog-width;
	height: $dialog-height;

	display: flex;
	flex-direction: column;

	background-color: $white;

	@mixin field {
		padding-left: $left-space;
		padding-right: $right-space;
		padding-bottom: 20px;
		display: flex;

		&__title{
			font-size: 11px;
			font-weight: 400;
			line-height: 15px;
		}
		// it is important to use selector more specific than ant.d one
		& &__value{
			display: flex;
			flex-direction: column;

			&__radio{
				height: 30px;
				line-height: 30px;
			}
		}
	}

	&__title{
		padding-left: $left-space;
		padding-right: $right-space;
		padding-top: 55px;
		padding-bottom: 15px;

		color: $gray1;
		font-size: 18px;
		font-weight: 600;
		line-height: 20px;
	}

	&__switcher{
		padding-left: $left-space;
		padding-right: $right-space;
		display: flex;
		flex-direction: row;
		padding-bottom: 15px;

		&__element{
			flex: 0 0 auto;
		}

		&__new{
			flex: 0 0 auto;
			margin: auto 0 auto auto;
		}
	}

	&__field{
		@include field();
		flex-direction: column;

		&__title{
			margin-bottom: 10px;
		}
	}

	&__container{
		padding-left: $left-space;
		margin-right: $right-space;
		flex: 1 1 auto;
		overflow-y: auto;
		overflow-x: hidden;
	}

	&__footer{
		padding-left: $left-space;
		padding-right: $right-space;
		flex: 0 0 auto;
		margin-top: auto;
		padding-top: 15px;
		margin-bottom: 15px;
		justify-self: flex-end;

		display: flex;
		flex-direction: row;
		justify-content: space-between;

		&__cancel{
			margin-left: auto;
		}
	}
}