@import '../../vars.scss';

.bra-transactions-table{
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;

	@include ant-table-fix();

	&__content{
		flex: 1 1 auto;
		overflow: auto;
	}

	&__pagination{
		flex: 0 0 auto;
		padding-top: 5px;
	}

	&__editing{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		&__content{
			margin-right: 8px;

			& > input{
				padding-left: 0;
				padding-right: 0;
			}
		}

		&__toggle{
			cursor: pointer;
			opacity: 0.1;
			transition: 100ms ease-in-out opacity;

			&--hidden{
				display: none;
			}

			&--highlighted,
			&:hover{
				opacity: 1.0;
			}
		}
	}

	&__cancel{
		opacity: 0.1;

		&:hover{
			opacity: 1;
		}

		&__menu{
			&__item{
				color: $gray1;
				& > *{
					vertical-align: middle;
				}	
			}
		}
	}

	&__numeric-value{
		display: inline-block;
		width: 100%;
		font-family: 'Lato';
		text-align: right;

		.feather-ico{
			cursor: default;
		}
	}

	&__canceled,
	&__canceling
	{
		color: $gray3;
	}
	
	&__positive-value{
		color: green;
	}
	&__negative-value{
		color: red;
	}

	&__filter{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
}