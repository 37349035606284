@import '../../vars.scss';

$menu-height: 40px;

.bra-top-menu{
	display: flex;
	flex-direction: row;
	justify-content: stretch;

	color: $white;
	background-color: $black;

	&__logo{
		flex: 0 0 auto;
		margin: 8px 56px 8px 16px;

		&__text{
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
		}
	}

	&__menu{
		flex: 1 1 auto;
		display: flex;

		@mixin menu-entry{
			&:hover{
				color: $white;
			}
			color: $gray4;
			font-size: 12px;
			font-weight: 600;
		}

		& &__title{
			@include menu-entry();
			display: inline-block;

			&__ico{
				margin-right: 0;
				margin-left: 10px;
			}
		}

		.ant-menu-submenu &__entry{
			@include menu-entry();
		}

		&__submenu{
			flex: 0 0 auto;

			a{
				color: rgba(255, 255, 255, 0.65);
			}

			&.pull-rigth{
				margin-left: auto;
			}

			.feather-ico{
				margin-bottom: -2px;
			}

			&.ant-menu-submenu-active{
				background-color: rgba(255, 255, 255, 0.2) !important;
			}
			&.bra-top-menu__menu__submenu--selected{
				background-color: rgba(255, 255, 255, 0.1) !important;
				a{
					color: $white;
				}
			}
		}

		&.ant-menu{
			&.ant-menu-dark{
				line-height: $menu-height;
				background-color: transparent;
			}
			&.ant-menu-submenu-title{
				display: flex;
			}
		}

		&__popup{
			& > .ant-menu{
				background-color: $gray1 !important;
				& .ant-menu-item-active{
					background-color: rgba(255, 255, 255, 0.2) !important;
				}
				& .ant-menu-item-selected{
					background-color: rgba(255, 255, 255, 0.1) !important;
				}
			}
		}
	}
}