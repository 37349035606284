@import './vars.scss';

body {
	margin: 0;
	font-family: 'Golos Text', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $text-color-common;
}

.grecaptcha-badge {
	visibility: hidden;
}

.feather-ico {
	height: 15px;
	width: 15px;
	margin-right: 8px;
	cursor: pointer;
}

.ant-notification{
	z-index: 20000;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

*{
	box-sizing: border-box;
	&::-webkit-scrollbar-track
	{
		background-color: transparent;
	}
	&::-webkit-scrollbar
	{
		height: 8px;
		width: 8px;
	}
	&::-webkit-scrollbar-thumb
	{
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 5px;
	}
}