@import '../../vars.scss';

$dialog-height: 320px;
$dialog-width: 400px;

.balance-attribute-dialog{
	left: calc(50% - #{$dialog-width/2});
	top: calc(50% - #{$dialog-height/2});
	height: $dialog-height;
	width: $dialog-width;
	background-color: $white;

	border-radius: 10px;
	display: flex;
	flex-direction: column;

	@mixin padded(){
		padding: 20px 40px 5px 40px;
		&:first-child{
			padding-top: 40px;
		}
		&:last-child{
			padding-bottom: 20px;
		}
	}

	&__title{
		@include padded();
		font-size: 18px;
		font-weight: 600;
		line-height: 20px;
		color: $gray1;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__field{
		@include padded();
		&__type{
			width: 100%;
		}
		&__is-required{
			width: 100%;
		}
	}

	&__footer{
		@include padded();
		margin-top: auto;
		justify-self: flex-end;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}