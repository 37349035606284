@import '../../vars.scss';

.transactions-container{
	height: 100%;
	width: 100%;

	@include ant-table-fix();

	&__header{
		&__icon{
			color: $gray3;
			margin-left: 10px;
		}
	}

	&__content{
		height: 100%;
		display: flex;
		flex-direction: column;

		&__table{
			flex: 1 1 auto;
			overflow: auto;
		}

		&__pagination{
			flex: 0 0 auto;
			padding-top: 5px;
		}

		&__editing{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			&__content{
				margin-right: 8px;
			}

			&__toggle{
				cursor: pointer;
				opacity: 0.1;
				transition: 100ms ease-in-out opacity;

				&--hidden{
					display: none;
				}

				&--highlighted,
				&:hover{
					opacity: 1.0;
				}
			}
		}
	}

	&__numeric-value{
		display: inline-block;
		width: 100%;
		font-family: 'Lato';
		text-align: right;
	}

	&__positive-value{
		color: green;
	}
	&__negative-value{
		color: red;
	}

	&__filter{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;

		&--highlighted{
			color: $blue10;
		}
	}
}