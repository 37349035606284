@import '../../vars.scss';

.page-title{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    &__title{
        flex: 1 1 auto;
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;


        &__text{
            font-size: 25px;
            font-weight: 600;
        }

        &__button-back{
            display: inline-block;
            margin-right: 15px;
            color: $text-color-emphasized;
        }
    }

    &__button{
        flex: 0 0 auto;
    }
}