@import '../../vars.scss';

.properties-section{
	border-bottom: $common-border-style;

    &:last-child {
        margin-top: auto;
        border-bottom-width: 0;
    }

    &__title{
        color: $text-color-emphasized;
        font-weight: 700;
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 15px;
	}

    &__footer{
		margin-top: 15px;
    }
}