@import '../../vars.scss';

@import './common.scss';

.bra-substring-filter{
	@include common-filter();

	&__content{
		$content: &;

		display: flex;
		flex-direction: row;
		align-items: center;

		border: 1px solid $gray4;
		border-radius: 5px;

		&__input{
			flex: 1 1 auto;
		}

		input#{$content}__input{
			background-color: transparent;
			border: none;
			outline: none;
		}


		&__x{
			flex: 0 0 auto;
			
			height: 18px;
			width: 18px;

			color: $gray4;
			cursor: pointer;
			transition: 100ms ease-in-out color;

			&:hover{
				color: $gray1;
			}
		}
	}
}