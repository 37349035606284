@import '../../vars.scss';

.bra-pagination{
	&__content{
		display: flex;

		&__button{
			margin: 5px 5px 0 5px;
		}

		&__current-page{
			flex: 0 0 auto;
			width: 50px;
			border: solid $gray3 1px;
			border-radius: 2px;
		}
	}
}