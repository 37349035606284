@import '../../vars.scss';

.menu-entry{
    display: flex;
    flex-direction: row;
    padding: 10px 0px 10px 20px;
    cursor: pointer;
    text-decoration: none;
    color: $gray1;
    transition: background-color 50ms ease-in-out;

    @mixin an-icon(){
        flex: 0 0 auto;
        display: flex;
        & > *{
            flex: 0 0 auto;
            margin: auto 0;
            display: inline-block;
            width: 15px;
            height: 15px
        }
    }

    &__icon{
        @include an-icon();
        margin-right: 10px;
    }

    &__text{
        flex: 1 1 auto;
    }

    &__alt-icon{
        @include an-icon();
        margin-right: 20px;
        margin-left: 10px;
    }

    &:hover:not(.disabled):not(.non-interactive){
        background-color: $gray4;
    }

    &.disabled{
        cursor: default;
        color: $gray3;
    }
    &.selected{
        background-color: $gray5;
    }
    &.small{
        padding: 7px 0px 8px 20px;

        @mixin small-icon(){
            & > *{
                width: 12px;
                height: 12px;
            }
        }
        .menu-entry__icon{
            @include small-icon();
        }
        .menu-entry__alt-icon{
            @include small-icon();
        }
    }

}