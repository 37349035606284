@import "../../vars.scss";

.transaction-wizard{
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100%;
	overflow: hidden;

	@mixin padded-element{
		padding: 20px;
	}

	&__conversion {
		&__checkbox {
			margin-bottom: 20px;
			label {
				display: flex;
				align-items: center;
				gap: 5px;
			}
		}
	}
	&__header{
		@include padded-element();

		background-color: $blue10;
		color: $white;

		&__top-menu{
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			&__back{
				display: flex;
				align-items: center;
				cursor: pointer;

				.feather-ico{
					margin-bottom: 2px;
				}
			}

			&__close{
				margin-left: auto;
			}
		}

		&__info{
			margin-top: 15px;
			&__type-name{
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
			}
		}

		&__nodes{
			margin-top: 15px;

			&__description{
				font-size: 13px;
				line-height: 16px;
			}

			&__notes{
				margin-top: 5px;
				font-size: 11px;
				line-height: 15px;
				color: $gray5;
			}

			&__entries{
				margin: 15px 0;
				padding: 10px;
				border: $common-border-style;
				border-radius: 5px;

				&__separator{
					margin: 10px 0;
					border-top: $common-border-style;
				}
			}

			&__expander{
				cursor: pointer;
			}
		}
	}

	& &__body{
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		&__attributes {
			border: unset!important;
			&__attribute {
				border-bottom: unset!important;
				& .ant-collapse-content {
					border-top: unset!important;
					background: inherit!important;
				}

				& .ant-collapse-content-box {
					padding-top: 0!important;
				}
			}
		}

		&__content{
			flex: 1 1 auto;
			@include padded-element();
			overflow: auto;

			&__custom-date,
			&__amount,
			&__comment
			{
				width: 100%;
			}
		}

		&__footer{
			@include padded-element();
			flex: 0 0 auto;

			&__ok{
				margin-bottom: 10px;
			}
			&__cancel{
				margin-bottom: 5px;
			}
		}
	}
}