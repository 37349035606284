@import '../../vars.scss';

.balances-list{
	padding: 20px 30px;
	display: flex;
	flex-direction: column;

	&__title{
		flex: 0 0 auto;
		display: flex;
		padding-bottom: 30px;
		font-size: 18px;
		font-weight: 600;
		line-height: 20px;

		&__text{
			flex: 0 0 auto;
			color: $gray3;
		}
		&__tree-name{
			flex: 0 0 auto;
			color: $gray1
		}
		&__new-button{
			flex: 0 0 auto;
			margin: 0 0 0 auto;
		}
	}

	&__table{
		flex: 1 1 auto;
		overflow-y: auto;
	}
}