@import '../../../vars.scss';

.records-list {
	&__entry {
		&__name {
			font-size: 11px;
			line-height: 15px;
			font-weight: 400;
			margin-bottom: 5px;
		}
		&__value {
			color: $text-color-emphasized;
			font-size: 13px;
			line-height: 15px;
			font-weight: 500;
			margin-bottom: 10px;
		}
	}

	&__collapser{
		&__text{
			cursor: pointer;

			font-size: 11px;
			font-weight: 400;
			line-height: 15px;
		}
	}
}
