@import '../../vars.scss';

.bra-formula-legend{
	&--over{
		box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
	}

	&__block{
		&__title{
			margin-right: 5px;
		}

		&__values{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			&__entry{
				border-left-color: transparent !important;
			}
		}
	}
}