@import '../../vars.scss';

.dnd-table{
	tr.drop-over-downward td {
		border-bottom: 2px dashed $blue10;
	}
	
	tr.drop-over-upward td {
		border-top: 2px dashed $blue10;
	}
}
