@import '../../vars.scss';

.dnd-list-entry{
	&.drop-before {
		border-bottom: 2px dashed $blue10;
	}
	
	&.drop-after {
		border-top: 2px dashed $blue10;
	}
}