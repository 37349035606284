@import '../../vars.scss';

.tabs{
	height: 35px;
	display: flex;
	flex-direction: row;
	background-color: $gray6;
	border-bottom: $common-border-style;
	font-size: 11px;
	
	@mixin an-tab(){
		padding: 8px;
		text-align: center;
		border-right: $common-border-style;
		cursor: pointer;
		color: $gray1;
	}

	&__tab{
		@include an-tab();
		width: 180px;
		position: relative;

		display: flex;

		box-shadow: inset 0 0 -1px rgba(0,0,0,0.1);
		transition: box-shadow 100ms ease-in-out;

		&--add{
			@include an-tab();

			&__ico{
				height: 18px;
				width: 18px;
			}
		}

		&__content{
			flex: 1 1 auto;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			a {
				color: $gray1;
			}
		}

		&:hover {
			color: $gray1;
			box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
			
			.tabs__tab__close{
				opacity: 1;
			}
		}

		&__close{
			flex: 0 0 auto;
			position: absolute;
			top: 10px;
			right: 15px;
			display: inline-block;
			margin: auto 10px auto 10px;
			cursor: pointer;
			opacity: 0;
			transition: opacity 200ms ease-in-out;


			& > *{
				vertical-align: baseline;
				height: 15px;
				width: 15px; 
			}
		}
	}
	
	&__tab.active{
		background-color: $white;
		cursor: default;
	}
}
