@import "../../vars.scss";

.tree-properties{
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100%;
	padding-top: 20px;
	overflow: hidden;

	@import './ActionPanel.scss';

	&  &__icon{
		width: 15px;
		height: 15px;
		cursor: pointer;
	}

	@mixin properties-element {
		margin-left: 20px;
		margin-right: 20px;
	}

	&__pre-header{
		@include properties-element();
		display: flex;
		flex-direction: row-reverse;

		&__x{
			margin-left: auto;
			flex: 0 0 auto;
		}
	}

	&__header{
		@include properties-element();
		
		flex: 0 0 auto;
		font-size: 11px;
		line-height: 15px;
		margin-bottom: 5px;
	}

	&__title{
		@include properties-element();
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
		font-size: 13px;
		line-height: 15px;
		font-weight: 600;
		color: $gray1;
		margin-bottom: 5px;
		
		& > *{
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__buttons{
		display: flex;
		flex-direction: row;
		border-top: $common-border-style;

		& > * {
			border-left: $common-border-style !important;
			padding-bottom: 5px;

			&:first-child{
				border-left-color: transparent !important;
			}
		}
	}
}