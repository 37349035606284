@import '../../vars.scss';

.bra-formula-entry{
	font-size: 11px;
	margin: 3px;
	padding: 2px;
	background-color: transparent;
	border-left: 2px dashed transparent;

	opacity: 1;

	&--dragging{
		opacity: 0.5;
	}

	&--over{
		border-left-color: $gray1;
	}

	&__colored{
		padding: 5px;
		border-radius: 5px;
		cursor: pointer;

		&--blue{
			background-color: $blue50;
		}
	
		&--orange{
			background-color: $orange10;
		}
	
		&--black{
			color: $white;
			background-color: $gray1;
		}
	}
}