@import '../../../vars.scss';

.attributes-filter{
	@mixin selectable {
		color: $blue30 !important;
	}
	&__btn{
		&__content{
			display: flex;
			align-items: center;

			&--set{
				@include selectable();
			}
		}
	}
	&__menu{
		&__attribute{
			&--set{
				* {
					@include selectable();
				}
			}
			&__value{
				&--set{
					* {
						@include selectable();
					}
				}
			}
			&__text{
				margin: 5px;
			}
		}
	}
}