@import '../../vars.scss';

$WIDTH: 400px;
$HEIGHT: 200px;

.bav-dialog{
	padding: 30px 50px 30px 50px;
	width: $WIDTH;
	height: $HEIGHT;
	background-color: $white;
	display: flex;
	flex-direction: column;

	&__title{
		color: $gray1;
		font-size: 18px;
		font-weight: 600;
		line-height: 20px;
		margin-bottom: 20px
	}

	&__footer{
		margin-top: auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}