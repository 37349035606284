@import '../../vars.scss';

.balances-page{
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: stretch;

	&__balances{
		flex: 1 1 auto;
	}
}