@import '../../../vars.scss';
@import './common.scss';

.node-info{
	@include base-properties-view();

	&__top-buttons{
		@include properties-element();

		&__button{
			margin-top: 15px;
			height: 35px;

			&__content{
				display: flex;

				&__main{
					flex: 1 1 auto;
				}
				&__addition{
					flex: 0 0 auto;

					.feather-ico{
						margin-right: 0;
					}
				}
			}

			&__transactions{
				color: $blue20;
			}
			&__transactions-elastic{
				margin-top: 0;
				color: $blue20;
			}
			&__transaction-changes{
				margin-top: 0;
				height: 30px;
				color: $blue20;
			}
		}
	}
}