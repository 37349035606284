@import '../../vars.scss';

.edit-attribute-value-panel{
	font-size: 11px;
	line-height: 15px;
	font-weight: 400;

	@mixin a-block(){
		padding: 5px 10px;

		&:first-child{
			padding-top: 10px
		}
	}

	&__header{
		@include a-block();
		color: $gray2;
	}
	&__name-block{
		@include a-block();
		& > input{
			font-size: 11px;
			line-height: 15px;
		}
	}
	&__visibility-block{
		@include a-block();

		&__list{
			display: flex !important;
			flex-direction: column !important;

			&__entry{
				padding: 5px !important;
				font-size: 11px !important;
				line-height: 15px !important;
			}	
		}
	}
	&__footer{
		@include a-block();
		display: flex;
		flex-direction: row;

		&__button{
			margin-right: 10px;

			&--right {
				margin-right: 0;
				margin-left: auto
			}
		}
	}
}