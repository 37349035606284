@import '../../vars.scss';

.operations-list{
	margin-left: 310px !important;

	&__title{
		word-wrap: break-word;
	}

	&__attribute{
		&__title{
			color: $gray2;
		}
		&__select{
			min-width: 100%;
		}
	}
}