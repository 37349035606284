@import '../../vars.scss';

.bra-formula-editor{
	height: 100%;
	overflow: hidden;

	display: flex;
	flex-direction: column;

	&__legend{
		flex: 1 1 auto;
		overflow: auto;
		margin-bottom: 5px;
	}

	&__content{
		display: flex;
		flex-direction: row;

		&__formula{
			flex: 1 1 auto;
			flex-wrap: wrap;
			overflow: auto;
			margin-right: 5px;
		}

		&__save{
			height: 100%;
		}
	}
}