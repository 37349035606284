@import '../../vars.scss';

.transactions-elastic-container{
	height: 100%;
	width: 100%;

	&__header{
		display: flex;
		flex-direction: row;

		&__attributes{
			margin-left: 20px;
		}

		&__icon{
			color: $gray3;
			margin-left: 10px;
		}
	}
}