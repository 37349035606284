@import '../../vars.scss';

.attributes-table{
	font-size: 11px;
	line-height: 15px;
	font-weight: 400;

	&__tr{
		border-bottom: $common-border-style;

		thead &, tbody &:last-child{
			border-bottom-color: transparent;
		}

		&__th{
			padding: 5px;
			color: $gray2;
		}

		&__td{
			padding: 20px 5px;
			color: $gray1;

			& .title{
				font-size: 13px;
				font-weight: 600;
			}
		}
	}

	&__v-centered{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;

		& > *{
			flex: 0 0 auto;
		}
	}

	&__attribute-groups{
		width: 100%;
		height: 100%;
	}

	&__wide-block{
		width: 100%;
	}

	&__actions{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		& > *{
			margin-left: 8px;
			flex: 0 0 auto;
		}
	}

	&__ico{
		width: 15px;
		height: 15px;
		margin-right: 8px;
		vertical-align: baseline;
	}
}