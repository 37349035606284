@import '../../vars.scss';

@mixin overlay(){
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 10000;
}

.custom-popup-overlay{
	@include overlay();

	backdrop-filter: blur(1px);
}

.custom-popup-overlay--transparent{
	@include overlay();
}

.custom-popup-view{
	position: absolute;
	pointer-events: all;
	z-index: 10001;

	border: $common-border-style;

	.ant-menu{
		font-size: 11px;
	}
}
