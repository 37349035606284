@import '../../vars.scss';

.tree-preview{
    float: left;
    width: 320px;
    margin: 15px 20px 15px 0;
    border: $common-border-style;
    border-radius: 15px;
    color: $gray1;

    &__header{
        position: relative;
        height: 120px;
        padding: 10px 0 0 0;
        border-bottom: $common-border-style;
        overflow: hidden;
        display: flex;

        &__img{
            margin: 0 auto;
        }

        &__actions{
            position: absolute;
            height: 20px;
            width: 20px;
            right: 10px;
            top: 10px;
        }
    }

    &__title{
        height: 50px;
        padding: 20px 20px 0 20px;
        display: flex;
        flex-direction: row;

        &__text{
            flex: 1 1 auto;
            font-size: 18px;
            font-weight: 600;
        }

        &__bookmark{
            flex: 0 0 auto;
            cursor: pointer;
            & > *{
                height: 20px;
                width: 20px;
            }
        }
    }

    &__details{
        height: 65px;
    }

    &__footer{
        height: 80px;
    }
}