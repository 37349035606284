@import './common.scss';

.bra-select-filter{
	@include common-filter();
	min-width: 180px;

	&__content{
		display: flex;
		flex-direction: row;
		align-items: center;

		&__select{
			flex: 1 1 auto;
		}

		&__x{
			flex: 0 0 auto;
			margin: 0 5px;

			height: 18px;
			width: 18px;
	
			color: $gray4;
			cursor: pointer;
			transition: 100ms ease-in-out color;
	
			&:hover{
				color: $gray1;
			}
		}
	}
}