@import '../../vars.scss';

.bra-button{
	padding: 0;
	background-color: transparent;
	border: $common-border-style;
	border-radius: 5px;
	height: 32px;
	font-size: 13px;
	line-height: 15px;
	font-weight: 400;
	transition: background-color 100ms ease-in-out;

	&.borderless{
		border-color: transparent;
	}

	&.small{
		height: unset;
		font-size: 11px;
		line-height: 15px;
		padding: 0;
	}

	&__wrapper{
		display: flex;
		align-items: center;
		text-align: center;
		cursor: pointer;
		white-space: nowrap;

		&__content{
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			padding: 2px 10px;
	
			&__icon{
				flex: 0 0 auto;
				margin-right: 10px;
				&:last-child{
					margin-right: 0;
				}
	
				& > svg{
					vertical-align: text-top;
					height: 18px;
					width: 18px;
					
					& *{
						stroke-width: 1.5;
					}
				}
			}

			&__text{
				flex: 1 1 auto;
				padding: 3px 0;
			}
		}
	}

	&--primary{
		@extend .bra-button;

		border: none;
		color: $white;
		background-color: $blue20;

		&:hover{
			background-color: $blue10;
		}
		&:disabled{
			background-color: $blue50 !important;
		}
	}

	&--danger{
		@extend .bra-button;

		color: $red;
	}

	&:disabled{
		border: 1px solid $gray6;
		background-color: $gray7;
		cursor: default;
	}

	&.block{
		width: 100%;
	}
}