@import '../../vars.scss';

@import './EditAttributeValuePanel.scss';

.attribute-values-list{
	display: flex;
	position: relative;

	&__selected-value{
		@include no-select();
		flex: 1 1 auto;
		cursor: pointer;
	}

	&__values-list{
		top: -12px;
		left: -12px;
		min-width: 100%;
		width: 300px;
		max-height: 240px;
		overflow-y: auto;
		background-color: $white;
		border-radius: 5px;

		@mixin an-element(){
			padding: 7px 10px 8px 10px;
			&:first-child{
				padding-top: 12px;
			}
			&:last-child{
				padding-bottom: 12px;
			}
		}

		&__entry{
			@include an-element();

			white-space: nowrap;
			display: flex;

			&.selected &__name{
				color: $blue10;
			}

			&:hover{
				background-color: rgba(0,0,0,0.1);
			}


			&__name{
				flex: 1 1 auto;
				margin-right: auto;
				overflow-x: hidden;
				text-overflow: ellipsis;
			}

			&__visibility{
				flex: 0 0 auto;
			}

			&__actions{
				flex: 0 0 auto;
				
				.feather-ico{
					margin: 0 5px 0 15px;
				}
			}
		}

		&__add-new{
			@include an-element();

			display: flex;
			flex-direction: row;
			align-items: center;
			border-top: $common-border-style;
			cursor: pointer;

			&__ico{
				width: 15px;
				height: 15px;
				margin-right: 8px;
			}
		}
	}
}