@import '../../vars.scss';

.bra-formula-panel{
	min-height: 37px;
	padding: 5px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	border: solid rgba(0, 0, 0, 0.1) 1px;
	border-radius: 5px;

	box-shadow: inset 0 0 0 rgba(0,0,0,0.1);
	transition: box-shadow 100ms ease-in-out;

	&--over{
		box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
	}

	&--error{
		border: solid 1px $red;
	}
}