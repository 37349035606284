@import '../../vars.scss';

.balance-attribute{
	display: flex;
	flex-direction: row;

	@mixin padded(){
		padding-top: 5px;
		padding-bottom: 5px;
	}

	@mixin ico(){
		width: 16px;
		height: 16px;
		vertical-align: text-bottom;
	}

	&__type{
		@include padded();
		flex: 0 0 auto;
		margin-right: 10px;
		cursor: move;

		&__ico{
			@include ico();
		}
	}

	&__name{
		@include padded();
		flex: 1 1 auto;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: $gray1;
	}

	&__values{
		@include padded();
		flex: 0 0 auto;
		width: 200px;

		&__list{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			overflow: hidden;

			&__entry{
				padding: 2px;

				&.drop-after{
					border: 0 dashed $blue10 !important;
					border-left-width: 2px !important;
				}
				&.drop-before{
					border: 0 dashed $blue10 !important;
					border-right-width: 2px !important;
				}
			}

			&__add{
				padding: 2px;
				display: flex;
				align-items: center;
				justify-content: center;

				&__icon{
					width: 20px;
					height: 20px;
					border-radius: 10px;
					cursor: pointer;
				}
			}
		}
	}

	&__actions{
		@include padded();
		flex: 0 0 30px;

		display: flex;
		align-content: center;
		justify-content: center;

		&__ico{
			@include ico();
			margin: auto;
			cursor: pointer;
		}
	}
}