@import '../../vars.scss';

.tree-selector{
	flex: 0 0 auto;
	min-width: 275px;
	display: flex;
	flex-direction: column;
	border-right: $common-border-style;

	&__title{
		flex: 0 0 auto;
		padding: 25px 30px 5px 50px;

		&__text{
			font-size: 18px;
			font-weight: 600;
			line-height: 20px;
			color: $gray1;
		}
	}

	&__content{
		flex: 1 1 auto;
		overflow-y: auto;
		padding: 20px 30px;

		&__entry{
			padding: 10px 20px;
			border-radius: 5px;
			cursor: pointer;
			border: 1px solid transparent;
			transition:
				background-color 100ms ease-in-out,
				border 100ms ease-in-out;
	
			&:hover{
				background-color: $gray6;
			}
	
			&.selected{
				border: $common-border-style;
			}
		}
	}
}