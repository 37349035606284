@mixin properties-element {
	margin-left: 20px;
	margin-right: 20px;
}

@mixin base-properties-view(){
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100%;
	overflow: hidden;

	&__section{
		@include properties-element();
		flex: 0 0 auto;
		padding: 20px 0;

		&:last-child {
			margin-top: auto;
		}
	}

	&__scroll-holder{
		flex: 1 1 auto;
		overflow: auto;
	}
}