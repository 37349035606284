@import '../../vars.scss';

.submenu{
    position: absolute;
    height: 0;
    width: 100%;
    overflow: hidden;
    margin: 0;

    &.expanded{
        height: unset;

        .submenu__content{
            border: $common-border-style;
        }
    }

    &__content{
        border-radius: 5px;
        margin: 0 20px;
        background-color: $white;
        border-color: transparent;
        transition:
            border 100ms ease-in-out,
            height 300ms ease-in-out;
    }
}
