@import '../../vars.scss';

$CLOSE_ICON_CONTAINER_SIZE: 18px;

.bav-element{
	padding: 2px 5px;
	color: $gray1;
	background-color: $gray6;
	border: $common-border-style;
	display: flex;
	flex-direction: row;
	align-items: center;

	&__text{
		margin-right: 5px;
	}

	&__close{
		height: $CLOSE_ICON_CONTAINER_SIZE;
		width: $CLOSE_ICON_CONTAINER_SIZE;
		display: flex;
		align-items: center;
		justify-content: center;

		cursor: pointer;
		border-radius: $CLOSE_ICON_CONTAINER_SIZE / 2;
		transition: background-color 50ms ease-in-out;

		&:hover{
			background-color: rgba(0, 0, 0, 0.1);
		}

		&__ico{
			width: 12px;
			height: 12px;
		}
	}
}
