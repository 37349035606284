@import '../../vars.scss';

@import './common.scss';

.bra-advanced-search{
	@include common-filter();

	display: flex;

	&__content{
		$content: &;
		flex: 1 1 auto;

		display: flex;
		flex-direction: row;
		align-items: center;

		border-width: 1px;
		border-style: solid;
		border-color: $strong-border-color;
		border-radius: 5px;

		font-size: 11px;

		&__input{
			flex: 1 1 auto;
			padding-left: 10px;
			padding-right: 10px;
		}

		input#{$content}__input{
			background-color: transparent;
			border: none;
			outline: none;
			// Reset default browser's width for input
			// Please ref: https://stackoverflow.com/questions/42421361/input-button-elements-not-shrinking-in-a-flex-container
			min-width: 0;
			width: 0;
		}

		&__label{
			user-select: none;
			color: $gray3;
		}

		@mixin buttonInInput(){
			flex: 0 0 auto;
			
			height: 15px;
			width: 15px;

			margin-right: 5px;
			margin-left: 5px;
			color: $gray1;
		}

		&__x{
			@include buttonInInput();

			border-radius: 10px;
			cursor: pointer;
			transition: 100ms ease-in-out color;

			&:hover{
				background-color: $gray6;
			}
		}

		&__search{
			@include buttonInInput();
		}
	}

	&__navigation{
		flex: 0 0 auto;

		display: flex;
		border-width: 1px;
		border-style: solid;
		border-color: $strong-border-color;
		border-radius: 5px;
		margin-left: 8px;

		&__button{
			display: flex;
			align-items: center;

			border-radius: 5px 0 0 5px;
			background-color: transparent;
			transition: background-color 100ms ease-in-out;


			&:not(:first-child){
				border-radius: 0 5px 5px 0;
				border-left: $common-border-style;
				border-color: $strong-border-color;
			}
			&:hover{
				background-color: $gray6;
			}

			&__ico{
				margin: 5px;
				height: 15px;
				width: 15px;
	
				color: $gray1;
				cursor: pointer;
			}
		}

	}
}