@import '../../vars.scss';

.tree-view{
	&__table{
		height: 100%;

		&__cell-content{
			padding: 16px;

			&--balance{
				font-family: 'Lato';
				text-align: right;
			}

			&__searched{
				&__text{
					padding-top: 2px;
					padding-bottom: 2px;
					background-color: $tree-selecton-row-color;
				}
			}

			&.highlight{
				background-color: $tree-selecton-row-color;
			}
			&.strong-highlight{
				background-color: $tree-selecton-cell-color;
			}
			&.highlighted-cell{
				color: $white;
				background-color: $blue30;
			}
			&.faded{
				color: $gray3;
			}
		}

		&__header-filter-dropdown{
			width: 250px;
		}
    
    .header-cell__text {
      overflow: visible !important;
    }

		&__header-cell-filter{
			height: 100%;

			display: flex;
			align-items: center;
			justify-content: center;

			& > svg{
				height: 15px;
				width: 15px;
			}

			&--active{
				& > svg{
					color: $blue10;
				}
			}
		}

		& .ant-table-row-indent + .ant-table-row-expand-icon{
			margin: 16px 8px 16px 16px;
		}

		.ant-table-thead > tr > th{
			padding: 0;
		}
	}
}