@import '../../vars.scss';

.transaction-change-logs-container{
	height: 100%;
	width: 100%;

	@include ant-table-fix();

	&__header{
		&__icon{
			color: $gray3;
			margin-left: 10px;
		}
	}

	&__content{
		height: 100%;
		display: flex;
		flex-direction: column;

		&__table{
			flex: 1 1 auto;
			overflow: auto;
		}

		&__pagination{
			flex: 0 0 auto;
			padding-top: 5px;
		}
	}

	&__entry__cell{
		display: flex;
		flex-direction: column;

		&__attribute-name {
			color: $gray3;
		}

		&__old-value{
			text-decoration: line-through;
		}
	}

	&__filter{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
}
