@import "../../vars.scss";

.node-in-tree{
	&__tree-name{
		font-weight: 600;
		font-size: 11px;
		line-height: 15px;
		color: $white;
		width: 100%;

		// patch antd styles (I hate mockups)
		&.ant-select.ant-select-borderless .ant-select-selector{
			padding-left: 0;
			color: $white;
			font-size: 11px;
			line-height: 15px;
		}
	}
}