@import '../../vars.scss';

.edit-buttons-set{
    &__menu{
        &__item{
            & > *{
                vertical-align: middle;
            }

            & a{
                color: $gray1;

                &:hover{
                    color: $gray2;
                }
            }
        }
    }
}