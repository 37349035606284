@import '../../vars.scss';

.attribute-groups-page{
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: stretch;

	&__attribute-groups{
		flex: 1 1 auto;
	}
}