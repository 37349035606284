.edit-user{
	&__content{
		&__footer{
			display: flex;
			flex-direction: row;
			&__cancel{
				margin-left: 10px;
			}	
		}
	}
}