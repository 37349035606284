@import '../../vars.scss';

.workspace{
	height: 100%;
	display: flex;
	flex-direction: row;

	&__left-column{
		height: 100%;
		flex: 1 1 auto;

		display: flex;
		flex-direction: column;
		overflow: hidden;

		@mixin view-element(){
			padding-left: 30px;
			padding-right: 18px;
			padding-bottom: 10px;
		}

		&__tabs{
			flex: 0 0 auto;
		}

		&__title{
			@include view-element();
			padding-top: 27px;
			flex: 0 0 auto;
		}

		&__content{
			@include view-element();
			flex: 1 1 auto;
			overflow: hidden;
		}
	}

	&__right-column{
		flex: 0 0 auto;
	}

}